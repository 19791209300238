import { Component } from '@angular/core';

@Component({
    selector: 'out-of-time',
    templateUrl: './out-of-time.component.html',
    styleUrls: ['./out-of-time.component.scss']
})
export class OutOfTimeComponent {

    close() {
        window.close();
    }
}
