export interface IPromiseSource {

  createDeferred<T = any>(): Deferred<T>;

  promise<T = any>(): Promise<T>;
}

export class Deferred<TD = any> implements IPromiseSource {

  private basePromise: Promise<any>;

  private _resolve: (value?: TD | PromiseLike<TD>) => void;

  private _reject: (reason?: any) => void;

  constructor() {
    this.basePromise = new Promise<TD>((rv, rj) => {
      this._resolve = rv;
      this._reject = rj;
    });
  }

  public resolve(value?: TD) {
    this._resolve(value);
  }

  public reject(reason?: any) {
    this._reject(reason);
  }

  public promise<T = any>(): Promise<T> {
    return new Promise<T>((rv, rj) => {
      this.basePromise.then(x => rv(x), x => rj(x));
    });
  }

  public createDeferred<T = any>(): Deferred<T> {
    const defer = new Deferred<T>();

    this.promise<T>().then(x => defer.resolve(x), x => defer.reject(x));

    return defer;
  }
}
