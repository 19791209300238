import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'unsupported-browser',
    templateUrl: './unsupported-browser.component.html',
    styleUrls: ['./unsupported-browser.component.scss']
})
export class UnsupportedBrowserComponent {

    isDesktop: boolean;

    isMobile: boolean;

    isIOs: boolean;

    linkToCopy: string;

    isLinkCopied: boolean;

    constructor(
        deviceDetectorService: DeviceDetectorService) {

        this.isMobile = deviceDetectorService.isMobile() || deviceDetectorService.isTablet();
        this.isDesktop = !this.isMobile;

        const deviceInfo = deviceDetectorService.getDeviceInfo();
        this.isIOs = deviceInfo.os.toLowerCase() === 'ios';

        this.linkToCopy = location.href;
    }

    onLinkCopied() {
        this.isLinkCopied = true;

        // simple un-click behavior
        setTimeout(
            () => {
                this.isLinkCopied = false;
            },
            1000);
    }
}
