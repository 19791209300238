import { Component } from '@angular/core';

@Component({
    selector: 'network-error',
    templateUrl: './network-error.component.html',
    styleUrls: ['./network-error.component.scss']
})
export class NetworkErrorComponent {

    tryAgain() {
        location.reload();
    }
}
