import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DeviceDetectorModule.forRoot()
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
