import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Publication } from '../../models/publication';

/**
 * Simple component to visualize publication video element.
 * Important: the component should not contain behavior that is not related to video/audio playback (since it's used in both mobile and desktop modes):
 * - so control elements like 'mute' should not be added here - use separate layer above <publication> instead
 * - the only extension that is supposed is a video/audio fallback (now it's done by OpenTok component)
 */
@Component({
    selector: 'publication',
    templateUrl: './publication.component.html',
    styleUrls: ['./publication.component.scss']
})
export class PublicationComponent implements OnChanges {
    @Input()
    publication: Publication;

    @ViewChild('publicationVideoElementContainer', { static: true })
    publicationVideoElementContainer: ElementRef<HTMLElement>;

    ngOnChanges(changes: SimpleChanges): void {
        const publicationPropertyName: keyof PublicationComponent = 'publication';
        if (changes.hasOwnProperty(publicationPropertyName)) {
            this.onPublicationUpdated();
        }
    }

    private onPublicationUpdated() {
        this.publicationVideoElementContainer.nativeElement.innerHTML = '';
        if (this.publication && this.publication.videoElementContainer) {
            this.publicationVideoElementContainer.nativeElement.append(this.publication.videoElementContainer);
        }
    }
}
