export class RegisterClientSessionCommandResponse {
  clientSessionUniqueId?: string | null;
}

export class RegisterClientSessionCommand {
  startedAt?: Date | null;
  startedAtUtc?: Date | null;
  userAgent?: string | null;
  initialUrl?: string | null;
}

export class RegisterClientSessionLogRecordsCommand {
  clientSessionUniqueId?: string | null;
  records?: ClientSessionLogRecordDto[] | null;
}

export class ClientSessionLogRecordDto {
  type: ClientSessionLogRecordTypeEnum;
  level: ClientSessionLogRecordLevelEnum;
  loggedAtUtc: Date;
  message?: string | null;
  dataJson?: string | null;
}

export enum ClientSessionLogRecordTypeEnum {
  Logger = 1,
  ConsoleLog = 2,
}

export enum ClientSessionLogRecordLevelEnum {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6,
}
