import { Component } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  componentTitle: string;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        tap(event => {
          if (event instanceof ActivationEnd) {
            if (event.snapshot.component) {
              this.componentTitle = event.snapshot.data.title;
            }
          }
        })
      )
      .subscribe();
  }
}
