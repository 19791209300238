import { APP_INITIALIZER, NgModule } from '@angular/core';
import { VideoRoomDataService, VideoRoomLoggerService } from 'video-lib';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DemoVideoRoomDataService } from './services/video-data.service';
import { DemoVideoRoomLoggerService } from './services/video-logger.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './menu/menu.component';
import { VideoRoomCoreModule } from 'video-lib';
import { VideoRoomTestPageComponent } from './video/connect.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    VideoRoomTestPageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    VideoRoomCoreModule
  ],
  providers: [
    {
      provide: VideoRoomDataService,
      useClass: DemoVideoRoomDataService
    },
    {
      provide: DemoVideoRoomLoggerService,
      useClass: DemoVideoRoomLoggerService,
    },
    {
      provide: VideoRoomLoggerService,
      useExisting: DemoVideoRoomLoggerService
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (logger: DemoVideoRoomLoggerService) => {
    //     return async () => {
    //       await logger.initAsync();
    //     };
    //   },
    //   deps: [DemoVideoRoomLoggerService],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
