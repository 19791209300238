import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'device-access-denied',
    templateUrl: './device-access-denied.component.html',
    styleUrls: ['./device-access-denied.component.scss']
})
export class DeviceAccessDeniedComponent implements OnInit {

    constructor() {}

    ngOnInit(): void {
    }

    // todo: Chrome allows to react on access restored in real-time - check doxy
}
