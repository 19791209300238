import { Component, Input, HostBinding, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { VideoRoomLoggerService } from '../../services/video-room-logger.service';
import { Room } from '../../models/room';
import { Subscription } from 'rxjs';
import { RoomState } from '../../models/room-state';
import { RoomStateChangedEvent } from '../../models/room-state-changed-event';
import { DeviceDetectorService } from 'ngx-device-detector';

declare type StripeMode = 'top' | 'left' | 'none';

@Component({
    selector: 'video-room-desktop',
    templateUrl: './video-room-desktop.component.html',
    styleUrls: ['./video-room-desktop.component.scss']
})
export class VideoRoomDesktopComponent implements OnChanges, OnDestroy {

    @Input()
    room: Room;

    @HostBinding('attr.data-side-visibility-mode')
    dataSideVisibilityMode: 'hidden' | 'normal' = 'hidden';

    private readonly _roomSubscriptions: Subscription[] = [];

    private _selectedStripeMode: StripeMode = 'top';

    isCommandPanelAlwaysVisible = true;

    get stripeMode(): StripeMode {
        if (!this.room || this.room.secondaryPublications.length === 0) {
            return 'none';
        }

        return this._selectedStripeMode;
    }

    isTouchDevice: boolean;

    constructor(
        deviceDetectorService: DeviceDetectorService,
        private _logger: VideoRoomLoggerService) {

        this.isTouchDevice = !deviceDetectorService.isDesktop();
    }

    ngOnDestroy(): void {
        this.cleanCurrentRoomSubscriptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const roomPropertyName: keyof VideoRoomDesktopComponent = 'room';
        if (changes.hasOwnProperty(roomPropertyName)) {
            this.subscribeToNewRoom();
        }
    }

    private cleanCurrentRoomSubscriptions() {
        for (const subscription of this._roomSubscriptions) {
            subscription.unsubscribe();
        }
        this._roomSubscriptions.length = 0;
    }

    // todo: simplify the code:
    // - we don't need to support of multiple rooms
    // - use '@Input set room()' instead of ngOnChanges
    private subscribeToNewRoom() {
        this.cleanCurrentRoomSubscriptions();

        if (!this.room) {
            return;
        }

        if (this.room.state === RoomState.Connecting) {
            // data side is hidden while it's connecting first time
            this.dataSideVisibilityMode = 'hidden';
        }
        else {
            // just in case if room was changed and it's not in Connecting state
            this.dataSideVisibilityMode = 'normal';
        }

        this._roomSubscriptions.push(
            this.room.roomStateChanged$.subscribe((event) => this.onRoomStateChanged(event))
        );
    }

    private onRoomStateChanged(event: RoomStateChangedEvent) {
        if (event.oldValue === RoomState.Connecting && event.newValue === RoomState.Connected) {
            // show data-side when it's connected first
            this.dataSideVisibilityMode = 'normal';

            if (!this.isTouchDevice) {
                // command panel is always visible until it's connected first time
                setTimeout(
                    () => {
                        this.isCommandPanelAlwaysVisible = true;
                    },
                    // 5 seconds after it's connected
                    5000);
            }
        }
    }

    setStripeMode(mode: StripeMode) {
        this._selectedStripeMode = mode;
        this._logger.trace(`selectedStripeMode = '${mode}'`);
    }

    // TODO: add displaying of audio level to audio icon
    // make it now via angular? check change detection strategy and performance impact
}
