import { VideoRoomLoggerService } from '../services/video-room-logger.service';
import { OpenTokSession } from './open-tok/open-tok-session';
import { Subject, Observable, Subscription } from 'rxjs';
import { DataChannelMessage } from './data-channel-message';
import { OpenTokSignalEvent } from './open-tok/open-tok-signal-event';

export class DataChannel {

    get messageReceived$(): Observable<DataChannelMessage> {
        return this.messageReceivedSubject.asObservable();
    }

    private readonly messageReceivedSubject = new Subject<DataChannelMessage>();

    private readonly _sessionSubscriptions: Subscription[] = [];

    constructor(
        private _openTokSession: OpenTokSession,
        private _logger: VideoRoomLoggerService) {

        // subscribe to session events
        this._sessionSubscriptions.push(
            this._openTokSession.signal$.subscribe((event) => this.onSignal(event)),
        );
    }

    destroy() {
        for (const subscription of this._sessionSubscriptions) {
            subscription.unsubscribe();
        }
    }

    private onSignal(event: OpenTokSignalEvent) {
        this.messageReceivedSubject.next({
            type: this.getMessageTypeByEventType(event.type),
            data: event.data,
        });
    }

    private static readonly SignalEventTypePrefix = 'signal:';

    private getMessageTypeByEventType(signalEventType: string) {
        if (!signalEventType || !signalEventType.startsWith(DataChannel.SignalEventTypePrefix)) {
            return signalEventType;
        }

        return signalEventType.substring(DataChannel.SignalEventTypePrefix.length);
    }

    async sendMessageAsync(message: DataChannelMessage) {
        const signalError = await this._openTokSession.signalAsync(message.type, message.data);
        if (signalError) {
            throw signalError;
        }
    }
}
