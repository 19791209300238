export abstract class VideoRoomLoggerService {

  abstract trace(message: string, data?: any);

  abstract info(message: string, data?: any);

  abstract warning(message: string, data?: any);

  abstract error(message: string, data?: any);
}
