import { Participant } from './participant';
import { VideoRoomLoggerService } from '../services/video-room-logger.service';
import { RemotePublication } from './remote-publication';
import * as OT from '@opentok/client';

export class RemoteParticipant extends Participant {

    participantId: string;

    publications: RemotePublication[] = [];

    constructor(
        participantId: string,
        participantFullName: string,
        private _logger: VideoRoomLoggerService) {
        super();

        this.isLocal = false;
        this.fullName = participantFullName;

        this.participantId = participantId;
    }

    destroy() {
        for (const publication of this.publications) {
            publication.destroy();
        }
    }

    addPublication(publication: RemotePublication) {
        this.publications.push(publication);
        publication.participant = this;
    }

    removePublication(publicationId: string): RemotePublication {
        const publicationIndex = this.publications.findIndex(x => x.publicationId === publicationId);
        if (publicationIndex === -1) {
            this._logger.error('publication_not_found', { publicationId: publicationId });
            return null;
        }

        const publication = this.publications[publicationIndex];
        this.publications.splice(publicationIndex, 1);
        publication.destroy();

        return publication;
    }

    static getParticipantId(otConnection: OT.Connection): string {
        return otConnection.connectionId;
    }

    static createFromConnection(otConnection: OT.Connection, logger: VideoRoomLoggerService): RemoteParticipant {
        let dataObject: any;
        try {
            dataObject = JSON.parse(otConnection.data);
        }
        catch (error) {
            logger.error('invalid_connection_data_json', error);
            dataObject = null;
        }
        
        const fullName = dataObject && dataObject.FullName
            ? dataObject.FullName
            : '';

        return new RemoteParticipant(
            RemoteParticipant.getParticipantId(otConnection),
            fullName,
            logger
        );
    }
}
