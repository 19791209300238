import { Component } from '@angular/core';

@Component({
    selector: 'waiting-for-agent',
    templateUrl: './waiting-for-agent.component.html',
    styleUrls: ['./waiting-for-agent.component.scss']
})
export class WaitingForAgent {

    tryAgain() {
        location.reload();
    }
}
