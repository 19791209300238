import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { Room } from '../../models/room';

@Component({
    selector: 'link-expired',
    templateUrl: './link-expired.component.html',
    styleUrls: ['./link-expired.component.scss'],
    providers: [Room]
})

export class LinkExpiredComponent {

    public alreadySent: boolean;

    constructor(      
        private room: Room,
        private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.alreadySent = false;
    }

    tryAgain() {
        location.reload();
    }

    actionMethod($event: any) {
        var roomId : string = this.route.snapshot.queryParamMap.get('id');
        (event.target as HTMLButtonElement).disabled = true;

        if (!this.alreadySent)
        {
            this.room.requestNewToken(roomId);
            this.alreadySent = true;
        }
    }
}
