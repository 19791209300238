import { Component, HostBinding, Input } from "@angular/core";
import { Room, VideoRoomLoggerService } from "video-lib";

@Component({
    selector: 'mic-permission',
    templateUrl: './mic-permission-screen.component.html',
    styleUrls: ['./mic-permission-screen.component.scss']
})
export class MicPermissionComponent {
    
    @Input()
    room: Room;

    @HostBinding('class.mobile-host')
    isMobile: boolean;

    constructor(
        protected _logger: VideoRoomLoggerService) {

    }
    tryAgain() {
        location.reload();
    }
}
