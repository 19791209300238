import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '../routes';

@Component({
  selector: 'menu-component',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  readonly components: { path: string, title: string }[];

  constructor(private router: Router) {
    const children = routes.find(x => x.path === 'video').children;
    this.components = children.map(x => ({
      path: x.path,
      title: x.data.title
    }));
  }

  isActive(currentRoute: any[], exact = true): boolean {
    return this.router.isActive(this.router.createUrlTree(currentRoute), exact);
  }
}
