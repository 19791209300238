export enum RoomState {
    Initializing = 1,
    UnsupportedBrowser,
    RequestingVideoRoomData,
    DeviceAccessRequesting,
    DeviceAccessDenied,
    Connecting,
    NetworkError,
    Connected,
    Ended,
    OutOfHours,
    AlreadyTaken,
    OutOfTime,
    LinkExpired
}
