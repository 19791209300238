import { Routes } from '@angular/router';
import { VideoRoomTestPageComponent } from './video/connect.component';

export const routes: Routes = [
    {path: '**',     
      data: {
        title: 'SetSchedule Hello'
      },
      component: VideoRoomTestPageComponent      
    ,pathMatch: 'full'}
  ];