import { GetVideoRoomInformation } from '../models/get-video-room-information';

export abstract class VideoRoomDataService {
    //change this too?
    abstract getVideoRoomDataAsync(tokenId: string, roomId :string, loginId : string, outOfHours?: boolean, cancellationToken?: Promise<any>): Promise<GetVideoRoomInformation>;
    // abstract getVideoRoomDataAsync(outOfHours: boolean, tokenId?: string, roomId? :string, loginId? : string): Promise<GetVideoRoomInformation>;
    
    abstract verifyRoomStatus(tokenId: string);

    abstract logOutOfHoursAttempt(tokenId: string);

    abstract disconnectVideoRoomConnections(sessionId: string);

    abstract expireConsumerToken(tokenId: string);

    abstract sendNewToken(tokenId: string);
}
