import { VideoRoomLoggerService } from '../../services/video-room-logger.service';
import { Subject } from 'rxjs';
import * as OT from '@opentok/client';
import { Deferred } from '../../helpers/deferred';
import { OpenTokPublisher } from './open-tok-publisher';
import { OpenTokStreamPropertyChangedEvent } from './open-tok-stream-property-changed-event';
import { OpenTokSignalEvent } from './open-tok-signal-event';

/*
 * Wrapper around OT.Session.
 */
export class OpenTokSession {

    get archiveStarted$() {
        return this.archiveStartedSubject.asObservable();
    }

    get archiveStopped$() {
        return this.archiveStoppedSubject.asObservable();
    }

    get connectionCreated$() {
        return this.connectionCreatedSubject.asObservable();
    }

    get connectionDestroyed$() {
        return this.connectionDestroyedSubject.asObservable();
    }

    get sessionConnected$() {
        return this.sessionConnectedSubject.asObservable();
    }

    get sessionDisconnected$() {
        return this.sessionDisconnectedSubject.asObservable();
    }

    get sessionReconnected$() {
        return this.sessionReconnectedSubject.asObservable();
    }

    get sessionReconnecting$() {
        return this.sessionReconnectingSubject.asObservable();
    }

    get signal$() {
        return this.signalSubject.asObservable();
    }

    get streamCreated$() {
        return this.streamCreatedSubject.asObservable();
    }

    get streamDestroyed$() {
        return this.streamDestroyedSubject.asObservable();
    }

    get streamPropertyChanged$() {
        return this.streamPropertyChangedSubject.asObservable();
    }

    private readonly archiveStartedSubject = new Subject<{ id: string; name: string; }>();
    private readonly archiveStoppedSubject = new Subject<{ id: string; name: string; }>();
    private readonly connectionCreatedSubject = new Subject<{ connection: OT.Connection; }>();
    private readonly connectionDestroyedSubject = new Subject<{ connection: OT.Connection; reason: string; }>();
    private readonly sessionConnectedSubject = new Subject();
    private readonly sessionDisconnectedSubject = new Subject<{ reason: string; }>();
    private readonly sessionReconnectedSubject = new Subject();
    private readonly sessionReconnectingSubject = new Subject();
    private readonly signalSubject = new Subject<OpenTokSignalEvent>();
    private readonly streamCreatedSubject = new Subject<{ stream: OT.Stream; }>();
    private readonly streamDestroyedSubject = new Subject<{ stream: OT.Stream; }>();
    private readonly streamPropertyChangedSubject = new Subject<OpenTokStreamPropertyChangedEvent>();

    constructor(
        public readonly otSession: OT.Session,
        private readonly _logger: VideoRoomLoggerService) {

        // subscribe to all events
        this.otSession.on('archiveStarted', (event) => this.onArchiveStarted(event));
        this.otSession.on('archiveStopped', (event) => this.onArchiveStopped(event));
        this.otSession.on('connectionCreated', (event) => this.onConnectionCreated(event));
        this.otSession.on('connectionDestroyed', (event) => this.onConnectionDestroyed(event));
        this.otSession.on('sessionConnected', () => this.onSessionConnected());
        this.otSession.on('sessionDisconnected', (event) => this.onSessionDisconnected(event));
        this.otSession.on('sessionReconnected', () => this.onSessionReconnected());
        this.otSession.on('sessionReconnecting', () => this.onSessionReconnecting());
        this.otSession.on('signal', (event) => this.onSignal(event));
        this.otSession.on('streamCreated', (event) => this.onStreamCreated(event));
        this.otSession.on('streamDestroyed', (event) => this.onStreamDestroyed(event));
        this.otSession.on('streamPropertyChanged', (event) => this.onStreamPropertyChanged(event));
    }

    destroy() {
        this.otSession.off('archiveStarted');
        this.otSession.off('archiveStopped');
        this.otSession.off('connectionCreated');
        this.otSession.off('connectionDestroyed');
        this.otSession.off('sessionConnected');
        this.otSession.off('sessionDisconnected');
        this.otSession.off('sessionReconnected');
        this.otSession.off('sessionReconnecting');
        this.otSession.off('signal');
        this.otSession.off('streamCreated');
        this.otSession.off('streamDestroyed');
        this.otSession.off('streamPropertyChanged');

        this.otSession.disconnect();
    }

    /**
     * @returns error or null if success.
     */
    async connectAsync(token: string): Promise<OT.OTError> {

        const deferred = new Deferred<OT.OTError>();

        this.otSession.connect(
            token,
            error => {
                if (error) {
                    this._logger.error('session_connection_error', error);
                    deferred.resolve(error);
                }
                else {
                    deferred.resolve(null);
                }
            });

        return deferred.promise();
    }

    /**
     * @returns error or null if success.
     */
    async publishAsync(openTokPublisher: OpenTokPublisher): Promise<OT.OTError> {

        const deferred = new Deferred<OT.OTError>();

        this.otSession.publish(
            openTokPublisher.otPublisher,
            (error?: OT.OTError) => {
                if (error) {
                    this._logger.error('session.publish: error', error);
                    deferred.resolve(error);
                }
                else {
                    this._logger.trace('session.publish: success');
                    deferred.resolve(null);
                }
            });

        return deferred.promise();
    }

    unpublish(openTokPublisher: OpenTokPublisher) {
        this.otSession.unpublish(openTokPublisher.otPublisher);
    }

    async signalAsync(type?: string, data?: string): Promise<OT.OTError> {

        const deferred = new Deferred<OT.OTError>();

        this.otSession.signal(
            { type: type, data: data },
            (error?: OT.OTError) => {
                if (error) {
                    this._logger.error('session.signal: error', error);
                    deferred.resolve(error);
                }
                else {
                    deferred.resolve(null);
                }
            });

        return deferred.promise();
    }

    //#region Session's event handlers

    private onArchiveStarted(event: OT.Event<'archiveStarted', OT.Session> & { id: string; name: string; }) {
        this._logger.trace('session.archiveStarted', { id: event.id });
        this.archiveStartedSubject.next(event);
    }

    private onArchiveStopped(event: OT.Event<'archiveStopped', OT.Session> & { id: string; name: string; }) {
        this._logger.trace('session.archiveStopped', { id: event.id });
        this.archiveStoppedSubject.next(event);
    }

    private onConnectionCreated(event: OT.Event<'connectionCreated', OT.Session> & { connection: OT.Connection }) {
        this._logger.trace('session.connectionCreated', { connectionId: event.connection.connectionId });
        //this consoles whenever someone joins successfully into the room
        this.playEnterAudio();
        this.connectionCreatedSubject.next(event);
    }

    private onConnectionDestroyed(event: OT.Event<'connectionDestroyed', OT.Session> & { connection: OT.Connection, reason: string; }) {
        this._logger.trace('session.connectionDestroyed', { connectionId: event.connection.connectionId, reason: event.reason });
        this.playExitAudio();
        this.connectionDestroyedSubject.next(event);
    }

    private onSessionConnected() {
        this._logger.trace('session.sessionConnected', { connection: this.otSession.connection });
        this.sessionConnectedSubject.next();
    }

    private onSessionDisconnected(event: OT.Event<'sessionDisconnected', OT.Session> & { reason: string; }) {
        this._logger.trace('session.sessionDisconnected', { reason: event.reason });
        this.sessionDisconnectedSubject.next(event);
    }

    private onSessionReconnected() {
        this._logger.trace('session.sessionReconnected');
        this.sessionReconnectedSubject.next();
    }

    private onSessionReconnecting() {
        this._logger.trace('session.sessionReconnecting');
        this.sessionReconnectingSubject.next();
    }

    private onSignal(event: OpenTokSignalEvent) {
        // no logging at the moment
        this.signalSubject.next(event);
    }

    private onStreamCreated(event: OT.Event<'streamCreated', OT.Session> & { stream: OT.Stream }) {
        this._logger.trace('session.streamCreated', { stream: event.stream });
        this.streamCreatedSubject.next(event);
    }

    private onStreamDestroyed(event: OT.Event<'streamDestroyed', OT.Session> & { stream: OT.Stream, reason: string }) {
        this._logger.trace('session.streamDestroyed', { reason: event.reason, streamId: event.stream.streamId });
        this.streamDestroyedSubject.next(event);
    }

    private onStreamPropertyChanged(event: OpenTokStreamPropertyChangedEvent) {
        this._logger.trace(
            'session.streamPropertyChanged',
            {
                streamId: event.stream.streamId,
                changedProperty: event.changedProperty,
                oldValue: event.oldValue,
                newValue: event.newValue
            });
        this.streamPropertyChangedSubject.next(event);
    }

    public playEnterAudio(){
        const audio = new Audio("assets/audio/enter.wav");
        audio.load();
        audio.play();
    }
    public playExitAudio(){
        const audio = new Audio("assets/audio/answeringbeep.mp3");
        audio.load();
        audio.play();
    }
    //#endregion
}
