import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'landing-page-desc',
    templateUrl: './landing-page-desc.component.html',
    styleUrls: ['./landing-page-desc.component.scss']
})
export class LandingDesctopPageComponent{
    
    @Input() name: string;

    @Output() onCloseButton = new EventEmitter();
    
    public onButtonClick(event){
        event.stopPropagation();
        this.onCloseButton.emit();
    }
}