import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { DeviceDetectorService } from 'ngx-device-detector';
import * as $ from "jquery";

@Component({
    selector: 'landing-page-mobile',
    templateUrl: './landing-page-mobile.component.html',
    styleUrls: ['./landing-page-mobile.component.scss']
})
export class LandingMobilePageComponent {

    isMobile: boolean;

    @Input() name: string;

    @Output() onCloseButton = new EventEmitter();

    constructor(
        private _deviceDetectorService: DeviceDetectorService) {

        this.isMobile = this._deviceDetectorService.isMobile();

        // todo: check if devices are presented at all
        // todo: check if devices not used by another application (like zoom or another tab is open)
    }

    ngOnInit(): void {                
        //need settimeout to make sure we handle the initial load. if we run it without any settimeout, nothing ends up happening
        setTimeout(() => {
            $("#slideshow > div:gt(0)").hide();            
        }, 1);        
        
        //set the slideshow aspect to continue at defined interval
        setInterval(() => {
            $("#slideshow > div:gt(0)").hide();
            $('#slideshow > div:first')
                .fadeOut(0)
                .next()
                .fadeIn(1500)                
                .appendTo('#slideshow');
        }, 5000);
    }

    public onButtonClick(event) {
        event.stopPropagation();
        this.onCloseButton.emit();
    }
}