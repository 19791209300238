import { Injectable } from '@angular/core';
import { DeviceInfo, DeviceDetectorService } from 'ngx-device-detector';
import { VideoRoomLoggerService } from './video-room-logger.service';
import { OpenTokSession } from '../models/open-tok/open-tok-session';
import * as OT from '@opentok/client';

/**
 * Helpers around OT SDK.
 */
@Injectable()
export class OpenTokService {

    readonly deviceInfo: DeviceInfo;    
    constructor(
        public readonly deviceDetectorService: DeviceDetectorService,
        private readonly _logger: VideoRoomLoggerService) {
        this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    }

    checkBrowserCompatibility(): boolean {
        if (OT.checkSystemRequirements() === 1) {
            if (this.deviceInfo.os.toLowerCase() === 'ios') {
                return this.deviceInfo.browser.toLowerCase() !== 'safari' || this.deviceInfo.browser.toLowerCase() !== 'chrome' || this.deviceInfo.browser.toLowerCase() !== 'firefox';
            }
            return true;
        }

        return false;
    }

    createSession(apiKey: string, sessionId: string): OpenTokSession {
        const otSession = OT.initSession(apiKey, sessionId);
        return new OpenTokSession(otSession, this._logger);
    }
}
