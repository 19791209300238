import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { VideoRoomLoggerService } from '../../services/video-room-logger.service';
import { Room } from '../../models/room';

type MobileScreen = 'video' | 'chat';

type DeviceOrientation = 'portrait' | 'landscape';

@Component({
    selector: 'video-room-mobile',
    templateUrl: './video-room-mobile.component.html',
    styleUrls: ['./video-room-mobile.component.scss']
})
export class VideoRoomMobileComponent {

    // todo: use the same handling as VideoRoomDesktopComponent does
    // - subscribe to onRoomStateChanged
    // - show menu with animation effect (tbd: on first connect?)
    // - ignore switchMenu() until it's not connected first time
    @Input()
    room: Room;

    @HostBinding('attr.device-orientation')
    deviceOrientation: DeviceOrientation;

    @HostBinding('attr.screen')
    screen: MobileScreen = 'video';

    isMenuVisible = true;

    getTimeRemainingString() { 
        return this.room._disconnectTimeRemainingString;
    }

    get isAudioMuted() {
        return this.room.localParticipant.localVideoPublication.isAudioMuted;
    }

    // short alias
    get isAudioMuting() {
        return this.room.localParticipant.localVideoPublication.isAudioMuting;
    }

    // short alias
    get isVideoMuted() {
        return this.room.localParticipant.localVideoPublication.isVideoMuted;
    }

    // short alias
    get isVideoMuting() {
        return this.room.localParticipant.localVideoPublication.isVideoMuting;
    }

    constructor(
        private _logger: VideoRoomLoggerService) {
        this.updateDeviceOrientation();
    }

    @HostListener('window:orientationchange')
    updateDeviceOrientation() {
        let orientationAngle: number;
        if (screen.orientation) {
            orientationAngle = screen.orientation.angle;
        }
        else {
            // workaround for iOs
            orientationAngle = +window.orientation;
        }

        if (orientationAngle % 180 === 0) {
            this.deviceOrientation = 'portrait';
        }
        else {
            this.deviceOrientation = 'landscape';
        }

        this._logger.trace(`deviceOrientation = '${this.deviceOrientation}'`);
    }

    switchMenu() {
        this._logger.trace('switchMenu()');

        this.isMenuVisible = !this.isMenuVisible;
    }

    flipCamera() {
        this._logger.trace('flipCamera()');

        // todo: disable command/ignore call until video device is changed
        this.room.localParticipant.localVideoPublication.cycleVideoAsync();
    }

    showTimeRemaining() : boolean {
        return this.room._isLead;
    }

    endCall() {
        this.room.end('end_call_command');
    }

    openChat() {
        this._logger.info('openChat()');

        this.screen = 'chat';
    }

    closeChat() {
        this._logger.info('closeChat()');

        this.screen = 'video';
    }

    toggleAudio() {
        this.room.localParticipant.localVideoPublication.toggleAudio();
    }

    toggleVideo() {
        this.room.localParticipant.localVideoPublication.toggleVideo();
    }
}
