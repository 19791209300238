import { Component } from '@angular/core';

@Component({
    selector: 'already-taken',
    templateUrl: './already-taken.component.html',
    styleUrls: ['./already-taken.component.scss']
})
export class AlreadyTakenComponent {

    close() {
        window.close();
    }
}
