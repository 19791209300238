import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VideoRoomLoggerService } from 'video-lib';
import { ClientSessionLogRecordTypeEnum } from 'src/app/models/models';
import { RegisterClientSessionCommand } from 'src/app/models/models';
import { RegisterClientSessionCommandResponse } from 'src/app/models/models';
import { ClientSessionLogRecordDto } from 'src/app/models/models';
import { RegisterClientSessionLogRecordsCommand } from 'src/app/models/models';
import { ClientSessionLogRecordLevelEnum } from 'src/app/models/models';
import * as moment from 'moment';

type ConsoleLogEvent = CustomEvent<{ methodName: keyof Console; arguments: any[] }>;

@Injectable()
export class DemoVideoRoomLoggerService extends VideoRoomLoggerService {

    constructor(private _http: HttpClient) {
        super();
    }

    private _clientSessionUniqueId: string;

    private _logRecords: ClientSessionLogRecordDto[] = [];

    async initAsync() {
        try {
            return;
            // // todo: RegisterClientSessionCommand should be interface?
            // const request: RegisterClientSessionCommand = new RegisterClientSessionCommand();
            // request.initialUrl = location.href;
            // request.userAgent = window.navigator.userAgent;
            // const nowMoment = moment();
            // // todo: fix - how to pass local datetime via Date object?
            // request.startedAt = nowMoment.toDate();
            // request.startedAtUtc = nowMoment.toDate();

            // const response = await this._http.post<RegisterClientSessionCommandResponse>(
            //     'api/ClientSessionLog/RegisterClientSession',
            //     request
            // ).toPromise();

            // this._clientSessionUniqueId = response.clientSessionUniqueId;
            // this.subscribeToExtendedLoggingSources();
            // this.runClientLogSync();

        }
        catch (error) {
            console.error('LoggerService initialization failed.', error);
        }
    }

    private _syncPeriodMs = 1000;

    private runClientLogSync() {
        this.scheduleNextLogSending();
    }

    private scheduleNextLogSending() {
        setTimeout(
            async () => {
                await this.sendLogRecordsAsync();
                this.scheduleNextLogSending();
            },
            this._syncPeriodMs);
    }

    private async sendLogRecordsAsync() {
        if (this._logRecords.length === 0) {
            return;
        }
        return;
        // const logRecordsToSend = this._logRecords.splice(0, this._logRecords.length);
        // const request = new RegisterClientSessionLogRecordsCommand();
        // request.clientSessionUniqueId = this._clientSessionUniqueId;
        // request.records = logRecordsToSend;

        // try {
        //     await this._http.post<RegisterClientSessionCommandResponse>(
        //         'api/ClientSessionLog/RegisterClientSessionLogRecords',
        //         request
        //     ).toPromise();

        // }
        // catch (error) {
        //     // tbd: handle in different way? re-try couple of times (instead of losing records due to bad connection)?
        //     this.error('Previous SYNC failed.', error);
        // }
    }

    private logImplementation(
        type: ClientSessionLogRecordTypeEnum,
        level: ClientSessionLogRecordLevelEnum,
        message?: string,
        data?: any) {

        try {
            const logRecord = new ClientSessionLogRecordDto();
            logRecord.type = type;
            logRecord.level = level;
            logRecord.loggedAtUtc = moment.utc().toDate();
            logRecord.message = message;
            logRecord.dataJson = data ? JSON.stringify(data) : null;

            // tbd: save to sessionStorage to survive browser refresh (if yes, then session id should also survive browser refresh somehow)?
            this._logRecords.push(logRecord);

        }
        catch (error) {
            // ignore
        }
    }

    public trace(message: string, data?: any) {
        // info to avoid stack trace in console
        console.info(message, data);
        this.logImplementation(ClientSessionLogRecordTypeEnum.Logger, ClientSessionLogRecordLevelEnum.Information, message, data);
    }

    public info(message: string, data?: any) {
        console.info(message, data);
        this.logImplementation(ClientSessionLogRecordTypeEnum.Logger, ClientSessionLogRecordLevelEnum.Information, message, data);
    }

    public warning(message: string, data?: any) {
        console.warn(message, data);
        this.logImplementation(ClientSessionLogRecordTypeEnum.Logger, ClientSessionLogRecordLevelEnum.Warning, message, data);
    }

    public error(message: string, error?: any) {
        console.error(message, error);
        this.logImplementation(ClientSessionLogRecordTypeEnum.Logger, ClientSessionLogRecordLevelEnum.Error, message, error);
    }

    private _logConsoleLog = false;

    private subscribeToExtendedLoggingSources() {
        window.addEventListener(
            'console_log',
            (event: ConsoleLogEvent) => {
                if (!this._logConsoleLog || !event.detail) {
                    return;
                }

                let logLevel: ClientSessionLogRecordLevelEnum;
                switch (event.detail.methodName) {
                    case 'debug':
                        logLevel = ClientSessionLogRecordLevelEnum.Debug;
                        break;
                    case 'trace':
                        logLevel = ClientSessionLogRecordLevelEnum.Trace;
                        break;
                    case 'info':
                        logLevel = ClientSessionLogRecordLevelEnum.Information;
                        break;
                    case 'warn':
                        logLevel = ClientSessionLogRecordLevelEnum.Warning;
                        break;
                    case 'error':
                        logLevel = ClientSessionLogRecordLevelEnum.Error;
                        break;
                    case 'log':
                    default:
                        logLevel = ClientSessionLogRecordLevelEnum.None;
                        break;
                }

                this.logImplementation(
                    ClientSessionLogRecordTypeEnum.ConsoleLog,
                    logLevel,
                    null,
                    event.detail.arguments);
            });
    }

    public enableExtendedLogging(
        settings: {
            consoleLog: boolean;
        }) {
        this._logConsoleLog = settings.consoleLog;
    }
}
