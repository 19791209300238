import { Observable, Subject, Subscription, timer } from 'rxjs';

import { DataChannel } from './data-channel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ENGINE_METHOD_DIGESTS } from 'constants';
import { GetVideoRoomInformation } from './get-video-room-information';
import { Injectable } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { LocalParticipant } from './local-participant';
import { LoggingHelperService } from '../services/logging-helper.service';
import { OpenTokService } from '../services/open-tok.service';
import { OpenTokSession } from './open-tok/open-tok-session';
import { ParticipantsChangedEvent } from './participants-changed-event';
import { Publication } from './publication';
import { PublicationType } from './publication-type';
import { RemoteParticipant } from './remote-participant';
import { RemotePublication } from './remote-publication';
import { RoomEndReason } from './room-end-reason';
import { RoomState } from './room-state';
import { RoomStateChangedEvent } from './room-state-changed-event';
import { VideoRoomDataService } from '../services/video-room-data.service';
import { VideoRoomLoggerService } from '../services/video-room-logger.service';

/**
 * Root object that represents all state and technical aspects of video-chat room.
 */
@Injectable()
export class Room {

    private _state: RoomState = RoomState.Initializing;
    public _disconnectTimeRemaining: number = 7200;
    public _disconnectTimeRemainingString: string;
    public _expireTimeRemaining: number = 300;
    public _expireTimeRemainingString: string;
    public _isAgent : boolean;
    public _isLead : boolean;
    private _roomId: string;
    private _tokenId: string;

    get state(): RoomState {
        return this._state;
    }

    set state(value: RoomState) {
        if (this._state === value) {
            this._logger.warning(`state is already '${this._loggingHelperService.getRoomStateReadableName(value)}'`);
            return;
        }

        const roomStateChangedEvent: RoomStateChangedEvent = {
            oldValue: this._state,
            newValue: value,
        };

        this._state = value;
        this._logger.trace(`state = '${this._loggingHelperService.getRoomStateReadableName(value)}'`);

        // set default status message
        switch (this._state) {
            case RoomState.Connecting:
                this._statusMessage = 'Connecting';
                break;
            case RoomState.Ended:
                this._statusMessage = 'Ended'
                break;            
        }

        this.roomStateChangedSubject.next(roomStateChangedEvent);
    }

    private _statusMessage: string;

    get statusMessage(): string {
        if (this._statusMessage) {
            return this._statusMessage;
        }
        else if (this.waitingForOthersMessage && this.remoteParticipants.length === 0) {
            return this.waitingForOthersMessage;
        }
        else {
            return null;
        }
    }

    waitingForOthersMessage: string;

    localParticipant: LocalParticipant;

    remoteParticipants: RemoteParticipant[] = [];

    dataChannel: DataChannel;

    private _getVideoRoomData: GetVideoRoomInformation;

    private _openTokSession: OpenTokSession;

    private readonly _sessionSubscriptions: Subscription[] = [];

    private _expirationSubscription : Subscription;

    private _disconnectSubscription : Subscription;

    //#region Events

    get roomStateChanged$(): Observable<RoomStateChangedEvent> {
        return this.roomStateChangedSubject.asObservable();
    }

    get recordingEnabled$(): Observable<boolean> {
        return this.recordingEnabledSubject.asObservable();
    }

    get sessionConnected$(): Observable<boolean> {
        return this.sessionConnectedSubject.asObservable();
    }

    get participantsChanged$(): Observable<ParticipantsChangedEvent> {
        return this.participantsChangedSubject.asObservable();
    }

    get roomEnded$(): Observable<RoomEndReason> {
        return this.roomEndedSubject.asObservable();
    }

    get dataChannelOpened$(): Observable<DataChannel> {
        return this.dataChannelOpenedSubject.asObservable();
    }

    private readonly roomStateChangedSubject = new Subject<RoomStateChangedEvent>();
    private readonly recordingEnabledSubject = new Subject<boolean>();
    private readonly sessionConnectedSubject = new Subject<boolean>();
    private readonly participantsChangedSubject = new Subject<ParticipantsChangedEvent>();
    private readonly roomEndedSubject = new Subject<RoomEndReason>();
    private readonly dataChannelOpenedSubject = new Subject<DataChannel>();

    //#endregion

    // main publication to display
    mainPublication: Publication;

    // list of all publications except main
    secondaryPublications: Publication[];

    constructor(
        private _openTokService: OpenTokService,
        protected _dataService: VideoRoomDataService,
        private _logger: VideoRoomLoggerService,
        private _deviceDetectorService: DeviceDetectorService,
        private _loggingHelperService: LoggingHelperService) {
    }

    private destroy() {
        if (this.localParticipant) {
            this.localParticipant.destroy();
        }

        for (const remoteParticipant of this.remoteParticipants) {
            remoteParticipant.destroy();
        }

        if (this.dataChannel) {
            this.dataChannel.destroy();
        }

        if (this._openTokSession) {
            this._openTokSession.destroy();
        }
        
        for (const subscription of this._sessionSubscriptions) {
            subscription.unsubscribe();
        }

        if (this._expirationSubscription != null)
        {
            this._expirationSubscription.unsubscribe();   
        }
        
        if (this._disconnectSubscription != null)
        {
            this._disconnectSubscription.unsubscribe();
        }        
    }

    end(reason: RoomEndReason) {
        if (this.state === RoomState.Ended) {
            return;
        }

        this.destroy();
        if (reason == "out_of_time")
        {
            this.state = RoomState.OutOfTime
        }
        else
        {
            this.state = RoomState.Ended;
            this.disconnectTimer.arguments
        }
        this._logger.trace('room_ended', { reason: reason });
        this.roomEndedSubject.next(reason);
    }

    disconnectTimer() {
        this._disconnectTimeRemaining = 7200;
        const source = timer(1000, 2000);
        this._disconnectSubscription = 
            source.subscribe(val => {
            if (this._disconnectTimeRemaining >= 0)
            {
                this._disconnectTimeRemaining = this._disconnectTimeRemaining - 1;
                // this._disconnectTimeRemainingString = this.secondsToHms(this._disconnectTimeRemaining);
            }
            else
            {
                //make request to disconnect everyone
                this._dataService.disconnectVideoRoomConnections(this._getVideoRoomData.videoRoomData.openTokSessionData.sessionId);
                this._disconnectSubscription.unsubscribe();
            }
        });        
      }

      expireTimer() {
        const source = timer(1000, 2000);
        this._expirationSubscription =
            source.subscribe(val => {            
            if (this._expireTimeRemaining > 0)
            {
                this._expireTimeRemaining = this._expireTimeRemaining - 1;
                // this._expireTimeRemainingString = this.secondsToHms(this._expireTimeRemaining);
            }
            else
            {
                //make request to expire the lead's current link
                this._dataService.expireConsumerToken(this._tokenId);
                this._expirationSubscription.unsubscribe();
            }          
        });
      }

      cancelExpireTimer() {
          this._sessionSubscriptions.pop();
      }

    secondsToHms(seconds) {
        seconds = Number(seconds);
        var h = Math.floor(seconds / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

        return hDisplay + mDisplay; 
    }

    async requestNewToken(tokenId : string)
    {
        this._dataService.sendNewToken(tokenId);
    }
    
    async runAsync(tokenId: string, roomId : string, loginId : string) {
        try {

            // check browser compatibility
            const isBrowserCompatible = this._openTokService.checkBrowserCompatibility();
            if (!isBrowserCompatible) {
                this.state = RoomState.UnsupportedBrowser;
                this._logger.warning('browser_not_supported', this._deviceDetectorService.getDeviceInfo());
                return;
            }

            // request room data
            const isVideoRoomDataReceived = await this.requestVideoRoomDataAsync(tokenId, roomId, loginId);
            if (!isVideoRoomDataReceived) {
                return;
            }

            this._tokenId = tokenId;
            // initialize session and related objects
            this.initializeSession();
            this.initializeDataChannel();

            // initialize local participant
            this.state = RoomState.DeviceAccessRequesting;
            this._isAgent = this._getVideoRoomData.isAgent;
            this._isLead = this._getVideoRoomData.isLead;
            this.localParticipant = new LocalParticipant(
                this._getVideoRoomData.videoRoomData.userFullName,
                this._openTokSession,
                this._logger,
                this._dataService,
                this._tokenId);
            await this.localParticipant.initializeVideoPublicationAsync();
            if (this.localParticipant.localVideoPublication.isDeviceAccessDenied) {
                this.state = RoomState.DeviceAccessDenied;
                return;
            }

            //make sure audio is disabled before they can even see the page.
            await this.localParticipant.localVideoPublication.toggleVideo();

            this.disconnectTimer();

            this.updatePublications();
            this.onParticipantsChanged();

            // connect to the session
            const isConnected = await this.connectToSessionAsync();
            if (!isConnected) {
                return;
            }

            //if we have no other participant, start the timer to expire the link
            if (isConnected && this.remoteParticipants.length < 1)
            {
                this.expireTimer();
            }

            // publish local video/audio
             const error = this.localParticipant.localVideoPublication.publishAsync()
             .then(
                 (errorCode) =>
                 {
                    if (errorCode == "NotAllowed")
                    {
                        this.state = RoomState.AlreadyTaken;
                    }
                }   
            );            
        }
        catch (error) {
            this._logger.error('Room.runAsync(): unhandled_error', error);
        }
    }

    private async requestVideoRoomDataAsync(tokenId: string, roomId : string, loginId : string): Promise<boolean> {
        var currentTime = new Date().toLocaleTimeString('en-US', { hour12: false, 
            hour: "numeric", 
            minute: "numeric"});        

        this.state = RoomState.RequestingVideoRoomData;

        try {
            var outOfHoursAttempt = false;
            if ((currentTime > '22:00' || currentTime < '07:00'))
            {
                outOfHoursAttempt = true;                
            }

            // tbd: use _componentCancellationTokenSource.promise() ?
            this._getVideoRoomData = await this._dataService.getVideoRoomDataAsync(tokenId, roomId, loginId, outOfHoursAttempt);
            this._logger.trace('room_data_received');

            this._isLead = this._getVideoRoomData.isLead;
            this._isAgent = this._getVideoRoomData.isAgent;            

            console.log(outOfHoursAttempt + ":", this._isLead);
            if (outOfHoursAttempt && this._isLead)
            {
                this.state = RoomState.OutOfHours;
                return false;
            }

            if (this._getVideoRoomData.errors != null)
            {
                var firstError = this._getVideoRoomData.errors[0];

                if (firstError.code == 'TokenAlreadyTaken')
                {
                    this.state = RoomState.AlreadyTaken;
                    return true;
                }                
                else if (firstError.code == 'TokenExpired' && this._isLead)
                {
                    this.state = RoomState.LinkExpired;
                    return true;
                }
                else if (firstError.code == 'TokenExpired' && this._isAgent)
                {
                    this.state = RoomState.AlreadyTaken;
                    return true;
                }
                else if (firstError.code == 'TokenError')
                {
                    this.state = RoomState.NetworkError;
                    return true;
                }
                else
                {
                    this.state = RoomState.NetworkError;
                }
            }

            if (this._getVideoRoomData.videoRoomData != null)
            {
                this.state = RoomState.NetworkError
            }

            return true;
        }
        catch (error) {
            // tbd: we can try 1 more time with some delay
            this._logger.error('getVideoRoomDataAsync()', error);
            // let user to retry
            this.state = RoomState.NetworkError;
            return false;
        }
    };

    private initializeSession() {
        this._openTokSession = this._openTokService.createSession(
            this._getVideoRoomData.videoRoomData.openTokSessionData.apiKey,
            this._getVideoRoomData.videoRoomData.openTokSessionData.sessionId);
        // subscribe to session events
        this._sessionSubscriptions.push(
            this._openTokSession.streamCreated$.subscribe((event) => this.addRemotePublication(event.stream)),
            this._openTokSession.streamDestroyed$.subscribe((event) => this.removeRemotePublication(event.stream)),
            this._openTokSession.archiveStarted$.subscribe(() => this.recordingEnabledSubject.next(true)),
            this._openTokSession.archiveStopped$.subscribe(() => this.recordingEnabledSubject.next(false)),
            this._openTokSession.sessionConnected$.subscribe(() => this.onSessionConnected()),
            this._openTokSession.sessionDisconnected$.subscribe((event) => this.onSessionDisconnected(event))
        );
    }

    private initializeDataChannel() {
        this.dataChannel = new DataChannel(this._openTokSession, this._logger);
        this.dataChannelOpenedSubject.next(this.dataChannel);
    }

    private async connectToSessionAsync(): Promise<boolean> {        
        this.state = RoomState.Connecting;

        if (this._isAgent)
        {
            var isAllowedToJoin = await this._dataService.verifyRoomStatus(this._tokenId); 
            if (!isAllowedToJoin)
            {
                this.state = RoomState.AlreadyTaken;
                return false;
            }            
        }        

        // connect to the session
        const connectionError = await this._openTokSession.connectAsync(this._getVideoRoomData.videoRoomData.openTokSessionData.token);
        if (connectionError) {
            // tbd: add support for unknown error?
            //if (error.name === 'OT_NOT_CONNECTED') {
            //    showMessage('Failed to connect. Please check your connection and try connecting again.');
            //} else {
            //    showMessage('An unknown error occurred connecting. Please try again later.');
            //}

            this.state = RoomState.NetworkError;
            return false;
        }

        if (this._isLead === true && this.remoteParticipants.length < 1)
        {
            this._statusMessage = "WaitingForAgent";
        }        
        else if (this._isLead === false && this.remoteParticipants.length < 1)
        {
            this._statusMessage = "WaitingForConsumer";
        }

        this.state = RoomState.Connected;
        return true;
    };

    private onSessionConnected() {
        this.sessionConnectedSubject.next(true);
    }

    private onSessionDisconnected(event: { reason: string }) {
        if (event.reason === 'networkDisconnected') {
            // todo: try to reconnect automatically couple times
            // tbd: show something special instead of <network-error> ?
            this.state = RoomState.NetworkError;
        }
        else if (event.reason === 'forceDisconnected')
        {
            this.state = RoomState.OutOfTime;
        }

        this.sessionConnectedSubject.next(false);
    }

    private addRemotePublication(stream: OT.Stream) {
        const remotePublication = new RemotePublication(stream, this._openTokSession, this._logger);
        // run stream receiving in parallel (without await)
        remotePublication.subscribeAsync();

        // find existing participant
        let remoteParticipant = this.remoteParticipants.find(x => x.participantId === remotePublication.participantId);
        let isNewParticipant: boolean;
        if (!remoteParticipant) {
            // create new participant by connection data (connection data should be specified during OT token issuing)
            remoteParticipant = RemoteParticipant.createFromConnection(stream.connection, this._logger);
            this.remoteParticipants.push(remoteParticipant);
            isNewParticipant = true;
        }
        else {
            isNewParticipant = false;
        }

        remoteParticipant.addPublication(remotePublication);
        this.updatePublications();

        if (isNewParticipant) {
            this.onParticipantsChanged();
        }
    }

    private removeRemotePublication(stream: OT.Stream) {
        const participantId = RemoteParticipant.getParticipantId(stream.connection);
        // find existing participant
        const remoteParticipant = this.remoteParticipants.find(x => x.participantId === participantId);
        if (!remoteParticipant) {
            this._logger.error('participant_not_found', { participantId: participantId });
            return;
        }

        const publicationId = RemotePublication.getPublicationId(stream);
        remoteParticipant.removePublication(publicationId);

        if (remoteParticipant.publications.length === 0) {
            this.remoteParticipants.splice(this.remoteParticipants.indexOf(remoteParticipant), 1);
            remoteParticipant.destroy();
            this.onParticipantsChanged();
        }

        this.updatePublications();
    }

    private updatePublications() {
        if (this.remoteParticipants.length > 0) {
            if (this._expirationSubscription != null)
            {
                this._expirationSubscription.unsubscribe();
            }

            //make sure that we remove the intro screens when someone joins the call.
            this._statusMessage = null;

            // flattening of all remote publications
            // https://schneidenbach.gitbooks.io/typescript-cookbook/functional-programming/flattening-array-of-arrays.html
            const allRemotePublications = ([] as RemotePublication[]).concat(...this.remoteParticipants.map(x => x.publications));

            // screen publications have priority
            const firstScreenPublication = allRemotePublications.find(x => x.type === PublicationType.Screen);
            if (firstScreenPublication) {
                this.mainPublication = firstScreenPublication;
            }
            else {
                // first remote publication
                this.mainPublication = allRemotePublications[0];
            }

            this.secondaryPublications = allRemotePublications.filter(x => x !== this.mainPublication);

            // local video publication has the lowest priority and it's displayed always last
            // local screen publication is not displayed (published only)
            this.secondaryPublications.push(this.localParticipant.localVideoPublication);
        }
        else {
            // only local participant on the call
            this.mainPublication = this.localParticipant.localVideoPublication;
            this.secondaryPublications = [];            
        }
    }

    private onParticipantsChanged() {
        this.participantsChangedSubject.next({
            totalParticipants: 1 + this.remoteParticipants.length,
        });
    }
}
