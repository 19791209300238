import { Component, Input } from '@angular/core';

@Component({
    selector: 'call-ended',
    templateUrl: './call-ended.component.html',
    styleUrls: ['./call-ended.component.scss']
})
export class CallEndedComponent {

    @Input()
    isConcierge: boolean;

    @Input()
    animaLeadLink: String;

    tryAgain() {
        location.reload();
    }

    close() {
        window.close();
    }
}
