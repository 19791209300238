import { PublicationType } from './publication-type';
import { Participant } from './participant';

export abstract class Publication {

    type: PublicationType;

    isLocal: boolean;

    videoElementContainer: HTMLElement;

    participant: Participant;

    isAudioMuted: boolean;

    constructor() {
        this.videoElementContainer = document.createElement('div');
        this.videoElementContainer.className = 'video-element-container';
    }

    abstract destroy();
}
