import { VideoRoomLoggerService } from '../services/video-room-logger.service';
import { Participant } from './participant';
import { LocalVideoPublication } from './local-video-publication';
import { VideoRoomDataService } from '../services/video-room-data.service';
import { OpenTokSession } from './open-tok/open-tok-session';
import * as OT from '@opentok/client';
import { LocalScreenPublication } from './local-screen-publication';

export class LocalParticipant extends Participant {

    localVideoPublication: LocalVideoPublication;

    isScreenSharingAvailable: boolean;

    localScreenPublication: LocalScreenPublication;

    constructor(
        participantFullName: string,
        private _openTokSession: OpenTokSession,
        private _logger: VideoRoomLoggerService,
        protected _dataService: VideoRoomDataService,
        private _roomId: string) {
        super();

        this.isLocal = true;
        this.fullName = participantFullName;

        this.checkScreenSharingCapability();

        this.localVideoPublication = new LocalVideoPublication(
            this,
            this._openTokSession,
            this._logger,
            this._dataService,
            this._roomId);

        this.localScreenPublication = new LocalScreenPublication(
            this,
            this._openTokSession,
            this._logger);
    }

    destroy() {
        if (this.localVideoPublication) {
            this.localVideoPublication.destroy();
        }
        if (this.localScreenPublication) {
            this.localScreenPublication.destroy();
        }
    }

    private checkScreenSharingCapability() {
        OT.checkScreenSharingCapability(response => {
            if (!response.supported || response.extensionRegistered === false) {
                this._logger.trace('screen_sharing_not_supported_by_browser');
            }
            else if (response.extensionInstalled === false) {
                this._logger.trace('screen_sharing_extension_not_installed');
            }
            else {
                this._logger.trace('screen_sharing_available');
                this.isScreenSharingAvailable = true;
            }
        });
    }

    /**
     * Request device access.
     * Start streaming video in html element.
     */
    async initializeVideoPublicationAsync(): Promise<any> {

        await this.localVideoPublication.initializeAsync();
    }
}
