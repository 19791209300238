import { Component, Input } from '@angular/core';
import { Publication } from '../../models/publication';
import { PublicationType } from '../../models/publication-type';

@Component({
    selector: 'publication-status-mobile',
    templateUrl: './publication-status-mobile.component.html',
    styleUrls: ['./publication-status-mobile.component.scss']
})
export class PublicationStatusMobileComponent {

    @Input()
    publication: Publication;

    PublicationType = PublicationType;
}
