import { Component, Input, HostBinding } from '@angular/core';
import { Room } from '../../models/room';
import { VideoRoomLoggerService } from '../../services/video-room-logger.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'device-access-requesting',
    templateUrl: './device-access-requesting.component.html',
    styleUrls: ['./device-access-requesting.component.scss']
})
export class DeviceAccessRequestingComponent {

    @Input()
    room: Room;

    @HostBinding('class.mobile-host')
    isMobile: boolean;

    constructor(
        protected _logger: VideoRoomLoggerService,
        private _deviceDetectorService: DeviceDetectorService) {

        this.isMobile = this._deviceDetectorService.isMobile();

        // todo: check if devices are presented at all
        // todo: check if devices not used by another application (like zoom or another tab is open)
    }
}
