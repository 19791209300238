import { AlreadyTakenComponent } from './components/already-taken/already-taken.component';
import { CallEndedComponent } from './components/call-ended/call-ended.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { DeviceAccessDeniedComponent } from './components/device-access-denied/device-access-denied.component';
import { DeviceAccessRequestingComponent } from './components/device-access-requesting/device-access-requesting.component';
import { LandingDesctopPageComponent } from './components/landing_page/landing-page-desc/landing-page-desc.component';
import { LandingMobilePageComponent } from './components/landing_page/landing-page-mobile.component';
import { LinkExpiredComponent } from './components/link-expired/link-expired.component';
import { LoggingHelperService } from './services/logging-helper.service';
import { MicPermissionComponent } from './components/mic-permission-screen/mic-permission-screen.component'
import { NetworkErrorComponent } from './components/network-error/network-error.component';
import { NgModule } from '@angular/core';
import { OpenTokService } from './services/open-tok.service';
import { OutOfHoursComponent } from './components/out-of-hours/out-of-hours.component';
import { OutOfTimeComponent } from './components/out-of-time/out-of-time.component';
import { PopoverModule } from 'ngx-smart-popover';
import { PublicationComponent } from './components/publication/publication.component';
import { PublicationStatusComponent } from './components/publication-status/publication-status.component';
import { PublicationStatusMobileComponent } from './components/publication-status-mobile/publication-status-mobile.component';
import { UnsupportedBrowserComponent } from './components/unsupported-browser/unsupported-browser.component';
import { VideoRoomComponent } from './components/video-room/video-room.component';
import { VideoRoomDesktopCommandPanelComponent } from './components/video-room-desktop-command-panel/video-room-desktop-command-panel.component';
import { VideoRoomDesktopComponent } from './components/video-room-desktop/video-room-desktop.component';
import { VideoRoomExtensionsDirective } from './components/video-room/video-room-extensions.directive';
import { VideoRoomMobileComponent } from './components/video-room-mobile/video-room-mobile.component';
import { WaitingForAgent } from './components/waiting-for-agent/waiting-for-agent.component';
import { WaitingForConsumer } from './components/waiting-for-consumer/waiting-for-consumer.component';

const PUBLIC_COMPONENTS = [
    VideoRoomComponent,
    VideoRoomExtensionsDirective
];

const SUB_COMPONENTS = [
    UnsupportedBrowserComponent,
    NetworkErrorComponent,
    DeviceAccessRequestingComponent,
    DeviceAccessDeniedComponent,
    VideoRoomDesktopComponent,
    VideoRoomDesktopCommandPanelComponent,
    VideoRoomMobileComponent,
    PublicationComponent,
    PublicationStatusComponent,
    PublicationStatusMobileComponent,
    WaitingForAgent,
    OutOfHoursComponent,
    CallEndedComponent,
    AlreadyTakenComponent,
    OutOfTimeComponent,
    WaitingForConsumer,
    LinkExpiredComponent,
    LandingDesctopPageComponent,
    LandingMobilePageComponent,
    MicPermissionComponent
];

@NgModule({
    declarations: [
        PUBLIC_COMPONENTS,
        SUB_COMPONENTS
    ],
    imports: [
        CommonModule,
        // todo: use Clipboard from Angular CDK
        ClipboardModule,
        PopoverModule
    ],
    providers: [
        OpenTokService,
        LoggingHelperService
    ],
    exports: [
        PUBLIC_COMPONENTS
    ]
})
export class VideoRoomCoreModule {
}
