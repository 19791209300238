import { Injectable } from '@angular/core';
import { RoomState } from '../models/room-state';
import { VideoRoomDisplayMode } from '../components/video-room/video-room-display-mode';
import { Room } from '../models/room';

@Injectable()
export class LoggingHelperService {

    getRoomStateReadableName(value: RoomState) {
        switch (value) {
            case RoomState.Initializing:
                return 'initializing';
            case RoomState.UnsupportedBrowser:
                return 'unsupported_browser';
            case RoomState.RequestingVideoRoomData:
                return 'requesting_video_room_data';
            case RoomState.NetworkError:
                return 'network_error';
            case RoomState.AlreadyTaken:
                return "already_taken";
            case RoomState.OutOfHours:
                return "out_of_hours";
            case RoomState.OutOfTime:
                return 'out_of_time';
            case RoomState.DeviceAccessRequesting:
                return 'device_access_requesting';
            case RoomState.DeviceAccessDenied:
                return 'device_access_denied';
            case RoomState.Connecting:
                return 'connecting';
            case RoomState.Connected:
                return 'connected';
            case RoomState.Ended:
                return 'ended';        
            default:
                return `${value}`;
        }
    }

    getVideoRoomDisplayModeReadableName(value: VideoRoomDisplayMode) {
        switch (value) {
            case VideoRoomDisplayMode.Desktop:
                return 'desktop';
            case VideoRoomDisplayMode.Mobile:
                return 'mobile';
            default:
                return `${value}`;
        }
    }
}
