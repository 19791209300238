import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { Room } from '../../models/room';
import { PopoverDirective, PopoverContentComponent } from 'ngx-smart-popover';
import { Device } from '../../models/device';

@Component({
    selector: 'video-room-desktop-command-panel',
    templateUrl: './video-room-desktop-command-panel.component.html',
    styleUrls: ['./video-room-desktop-command-panel.component.scss']
})
export class VideoRoomDesktopCommandPanelComponent {    
    @Input()
    room: Room;

    getTimeRemainingString() { 
        return this.room._disconnectTimeRemainingString;
    }

    // short alias
    get isAudioMuted() {
        return this.room.localParticipant.localVideoPublication.isAudioMuted;
    }

    // short alias
    get isAudioMuting() {
        return this.room.localParticipant.localVideoPublication.isAudioMuting;
    }

    // short alias
    get activeAudioSourceDeviceId() {
        return this.room.localParticipant.localVideoPublication.activeAudioSourceDeviceId;
    }

    // short alias
    get isVideoMuted() {
        return this.room.localParticipant.localVideoPublication.isVideoMuted;
    }

    // short alias
    get isVideoMuting() {
        return this.room.localParticipant.localVideoPublication.isVideoMuting;
    }

    // short alias
    get isScreenSharingAvailable() {
        // tbd: add check for 'connected' room state?
        return this.room.localParticipant.isScreenSharingAvailable;
    }

    // short alias
    get isScreenSharingStarting() {
        return this.room.localParticipant.localScreenPublication.isScreenSharingStarting;
    }

    // short alias
    get isScreenSharingStarted() {
        return this.room.localParticipant.localScreenPublication.isScreenSharingStarted;
    }

    isAudioSettingsOpen: boolean;

    get isAnyOptionSelectionOpen(): boolean {
        return this.isAudioSettingsOpen;
    }

    @ViewChildren(PopoverDirective)
    popovers: QueryList<PopoverDirective>;

    audioDevices: Device[] = [];    

    async onAudioSettingsPopoverOpen(isOpen: boolean) {
        this.isAudioSettingsOpen = isOpen;

        if (isOpen) {
            // update audio devices
            // todo: request devices first time on init
            // todo: react on new device in real-time (if popover is open) - tbd: LocalParticipant.audioDevices + own popover with bottom in appropriate placements (top, top-xxx)
            try {
                this.audioDevices = await this.room.localParticipant.localVideoPublication.getAudioDevicesAsync();
            }
            catch (error) {
                // should be logged in getAudioDevicesAsync(), just set default value
                this.audioDevices = [];
            }

            // update popover position after audioDevices are rendered
            setTimeout(() => {
                    const popoverInfo = this.getPopoverInfoByContentParentClass('audio-settings-popover');
                    if (popoverInfo) {
                        popoverInfo.popoverContent.updatePosition();
                    }
                },
                0);            
        }        
    }

    private getPopoverInfoByContentParentClass(contentParentClass: 'audio-settings-popover' | 'video-settings-popover'): { popover: PopoverDirective; popoverContent: PopoverContentComponent } {
        if (this.popovers) {
            for (const popover of this.popovers.map(x => x)) {
                const popoverContent = popover.content as PopoverContentComponent;
                if (popoverContent && popoverContent.parentClass && popoverContent.parentClass.includes(contentParentClass)) {
                    return {
                        popover: popover,
                        popoverContent: popoverContent
                    };
                }
            }
        }

        return null;
    }

    endCall() {
        this.room.end('end_call_command');
    }

    // TODO: add displaying of audio level to audio icon
    // make it now via angular? check change detection strategy and performance impact

    toggleAudio() {
        this.room.localParticipant.localVideoPublication.toggleAudio();
    }

    setAudioSource(device: Device) {
        // hide popover
        const popoverInfo = this.getPopoverInfoByContentParentClass('audio-settings-popover');
        if (popoverInfo) {
            popoverInfo.popover.hide();
        }

        // set audio source
        this.room.localParticipant.localVideoPublication.setAudioSource(device);
    }

    showTimeRemaining() : boolean {
        return this.room._isLead;
    }

    toggleVideo() {
        // todo: improve logging: add logs like ui.toggle_video - to cover all ui interactions
        this.room.localParticipant.localVideoPublication.toggleVideo();
    }

    toggleScreenSharing() {
        if (this.isScreenSharingStarted) {
            this.room.localParticipant.localScreenPublication.stopSharing();
        }
        else {
            this.room.localParticipant.localScreenPublication.startAsync();
        }
    }
}
