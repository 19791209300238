import * as OT from '@opentok/client';

import { Deferred } from '../helpers/deferred';
import { OpenTokPublisher } from './open-tok/open-tok-publisher';
import { OpenTokSession } from './open-tok/open-tok-session';
import { Participant } from './participant';
import { Publication } from './publication';
import { PublicationType } from './publication-type';
import { Subscription } from 'rxjs';
import { VideoRoomLoggerService } from '../services/video-room-logger.service';

export class LocalScreenPublication extends Publication {

    private _openTokPublisher: OpenTokPublisher;

    private readonly _publisherSubscriptions: Subscription[] = [];

    isScreenSharingStarting: boolean;

    isScreenSharingStarted: boolean;

    constructor(
        participant: Participant,
        private _openTokSession: OpenTokSession,
        private _logger: VideoRoomLoggerService) {
        super();

        this.type = PublicationType.Screen;
        this.isLocal = true;
        this.participant = participant;
    }

    destroy() {
        this.destroyOpenTokPublisherAndClearItsSubscriptions();
    }

    async startAsync(): Promise<OT.OTError> {

        if (this.isScreenSharingStarting || this.isScreenSharingStarted) {
            this._logger.warning('Screen sharing is starting or started (ignored)');
            return { name: 'started_already', message: '' };
        }

        this.isScreenSharingStarting = true;

        try {
            this._logger.trace('LocalScreenPublication_starting');

            const initializationDeferred = new Deferred<OT.OTError>();

            const otPublisher = OT.initPublisher(
                this.videoElementContainer,
                {
                    videoSource: 'screen',
                    insertMode: 'append',
                    width: '100%',
                    height: '100%',
                    fitMode: 'contain',
                    style: {
                        archiveStatusDisplayMode: 'off',
                        buttonDisplayMode: 'off'
                    }   
                },
                (error?: OT.OTError) => {
                    if (error) {
                        this._logger.error('OT.initPublisher: error', error);
                        initializationDeferred.resolve(error);
                    }
                    else {
                        this._logger.trace('OT.initPublisher: success');
                        initializationDeferred.resolve(null);
                    }
                }
            );

            this._openTokPublisher = new OpenTokPublisher(otPublisher, this._logger);
            // subscribe to publisher events
            this._publisherSubscriptions.push(
                this._openTokPublisher.streamDestroyed$.subscribe(() => this.onPublisherStreamDestroyed()),
            );

            const initializationError = await initializationDeferred.promise();
            if (initializationError) {
                this.destroyOpenTokPublisherAndClearItsSubscriptions();
                return initializationError;
            }

            // todo: Chrome shows Stop sharing panel before the publishing - think about UX
            // what will happen if Chrome's 'Stop sharing' is pressed in progress of publishing?

            const publishingError = await this.publishAsync();
            if (publishingError) {
                this.destroyOpenTokPublisherAndClearItsSubscriptions();
                return publishingError;
            }

            this.isScreenSharingStarted = true;
            return null;
        }
        finally {
            this.isScreenSharingStarting = false;
        }
    }

    private async publishAsync(): Promise<OT.OTError> {
        this._logger.trace('LocalScreenPublication_publishing');

        const error = await this._openTokSession.publishAsync(this._openTokPublisher);
        // todo: retry couple times if it's relevant
        return error;
    }

    private onPublisherStreamDestroyed() {
        this.isScreenSharingStarted = false;
        this.destroyOpenTokPublisherAndClearItsSubscriptions();
    }

    stopSharing() {
        if (!this.isScreenSharingStarted) {
            this._logger.warning('Screen sharing is not started (ignored)');
            return;
        }

        if (this._openTokPublisher) {
            // should trigger publisher.streamDestroyed event
            this._openTokSession.unpublish(this._openTokPublisher);
        }
        else {
            this._logger.error('stopSharing(): _openTokPublisher is null');
        }
    }

    private destroyOpenTokPublisherAndClearItsSubscriptions() {
        if (this._openTokPublisher) {
            this._openTokPublisher.destroy();
            this._openTokPublisher = null;
        }

        for (const subscription of this._publisherSubscriptions) {
            subscription.unsubscribe();
        }
        this._publisherSubscriptions.length = 0;
    }
}
