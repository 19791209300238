import { Component, Input } from '@angular/core';
import { Publication } from '../../models/publication';
import { PublicationType } from '../../models/publication-type';

@Component({
    selector: 'publication-status',
    templateUrl: './publication-status.component.html',
    styleUrls: ['./publication-status.component.scss']
})
export class PublicationStatusComponent {

    @Input()
    publication: Publication;

    PublicationType = PublicationType;
}
