import { Component, ContentChild, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { DataChannel } from '../../models/data-channel';
import { Deferred } from '../../helpers/deferred';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggingHelperService } from '../../services/logging-helper.service';
import { ParticipantsChangedEvent } from '../../models/participants-changed-event';
import { Room } from '../../models/room';
import { RoomEndReason } from '../../models/room-end-reason';
import { RoomState } from '../../models/room-state';
import { Subscription } from 'rxjs';
import { VideoRoomDataService } from 'video-lib';
import { VideoRoomDisplayMode } from './video-room-display-mode';
import { VideoRoomExtensionsDirective } from './video-room-extensions.directive';
import { VideoRoomLoggerService } from '../../services/video-room-logger.service';

/**
 * Root component that processes all UX/UI aspects of video-chat room.
 * Also it's used to forward room/session-level events in Output/EventEmitter way.
 */
@Component({
    selector: 'video-room',
    templateUrl: './video-room.component.html',
    styleUrls: ['./video-room.component.scss'],
    providers: [Room]
})
export class VideoRoomComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    forceDisplayMode?: VideoRoomDisplayMode;

    @Input()
    set waitingForOthersMessage(value: string) {
        this.room.waitingForOthersMessage = value;
    }

    @Output()
    recordingEnabled = new EventEmitter<boolean>();

    @Output()
    dataChannelOpened = new EventEmitter<DataChannel>();

    @Output()
    sessionConnected = new EventEmitter<boolean>();

    @Output()
    participantsChanged = new EventEmitter<ParticipantsChangedEvent>();

    @Output()
    roomEnded = new EventEmitter<RoomEndReason>();

    @ContentChild(VideoRoomExtensionsDirective, { read: TemplateRef, static: false })
    extensionsTemplate: TemplateRef<VideoRoomExtensionsDirective>;

    @HostBinding('class.room-ended')
    isRoomEnded: boolean;

    displayMode: VideoRoomDisplayMode;

    // enums in the template
    RoomState = RoomState;
    VideoRoomDisplayMode = VideoRoomDisplayMode;

    private _componentCancellationTokenSource = new Deferred();

    private readonly _roomSubscriptions: Subscription[] = [];

    public isShowWelcomeScreen: boolean = false; 
    public fullName: string; 
    
    private tokenId: string;
    private roomId: string;
    private loginId: string;


    constructor(
        // it's easier to resolve all room dependencies via DI (component level)
        public room: Room,
        protected _logger: VideoRoomLoggerService,
        private _deviceDetectorService: DeviceDetectorService,
        private _loggingHelperService: LoggingHelperService,
        protected _dataService: VideoRoomDataService,
        private route: ActivatedRoute) {        
        this.updateDisplayMode();

        this._roomSubscriptions.push(
            this.room.recordingEnabled$.subscribe((isEnabled) => this.recordingEnabled.emit(isEnabled)),
            this.room.dataChannelOpened$.subscribe((dataChannel) => this.dataChannelOpened.emit(dataChannel)),
            this.room.sessionConnected$.subscribe((isConnected) => this.sessionConnected.emit(isConnected)),
            this.room.participantsChanged$.subscribe((event) => this.participantsChanged.emit(event)),
            this.room.roomEnded$.subscribe((event) => this.onRoomEnded(event)),
        );
    }

    ngOnInit(): void {
        // tbd: pass and use _componentCancellationTokenSource inside room
        this.tokenId = this.route.snapshot.queryParamMap.get('id');
        this.roomId = this.route.snapshot.queryParamMap.get('roomId');
        this.loginId = this.route.snapshot.queryParamMap.get("loginId");
              
        this.initRoom();
        
    }

    ngOnChanges(changes: SimpleChanges) {
        // todo: we need better support for tablets, after this 'forceDisplayMode' that is needed to prevent mobile-mode for tablets (for specific users) can be removed
        const forceDisplayModePropertyName: keyof VideoRoomComponent = 'forceDisplayMode';
        if (changes.hasOwnProperty(forceDisplayModePropertyName)) {
            this.updateDisplayMode();
        }
    }

    ngOnDestroy(): void {
        this._componentCancellationTokenSource.resolve();

        this.room.end('component_destroy');

        // subscriptions are freed after room.end() to process roomEnded event
        for (const subscription of this._roomSubscriptions) {
            subscription.unsubscribe();
        }
    }

    private onRoomEnded(reason: RoomEndReason) {
        this.isRoomEnded = true;
        this.roomEnded.emit(reason);
    }

    private updateDisplayMode() {
        let newValue: VideoRoomDisplayMode;
        if (this.forceDisplayMode) {
            newValue = this.forceDisplayMode;
        }
        else {
            newValue = this._deviceDetectorService.isMobile() || this._deviceDetectorService.isTablet()
                ? VideoRoomDisplayMode.Mobile
                : VideoRoomDisplayMode.Desktop;
        }

        if (this.displayMode !== newValue) {
            this.displayMode = newValue;
            this._logger.trace(`displayMode = '${this._loggingHelperService.getVideoRoomDisplayModeReadableName(newValue)}'`);
        }
    }

    closeWelcomeScreen(){
        this.isShowWelcomeScreen = false;
        this.room.runAsync(this.tokenId, this.roomId, this.loginId);
    }

    initRoom(){
        
        this._dataService.getVideoRoomDataAsync(this.tokenId,this.roomId,this.loginId, false).then(response=>{

            if(response && response.succeeded && response.isLead){
                this.isShowWelcomeScreen = true;
                this.fullName = response.videoRoomData.userFullName;
                if (this.fullName)
                {
                    this.fullName = this.fullName[0].toUpperCase() + this.fullName.slice(1).toLowerCase()
                }

                return;
            }

            this.isShowWelcomeScreen = false;
            if(response && response.videoRoomData)                
                this.fullName = response.videoRoomData.userFullName;
                if (this.fullName)
                {
                    this.fullName = this.fullName[0].toUpperCase() + this.fullName.slice(1).toLowerCase()
                }
            this.room.runAsync(this.tokenId, this.roomId, this.loginId);
        });
    }

    showRoom(): boolean {
        return this.room.state === RoomState.Connecting ||
            this.room.state === RoomState.Connected;
    }    

    // tbd: each time when doxy changes audioInput device it also reload video stream - why?
    // todo: settings
}
