import * as OT from '@opentok/client';

import { Deferred } from '../helpers/deferred';
import { Device } from './device';
import { OpenTokPublisher } from './open-tok/open-tok-publisher';
import { OpenTokSession } from './open-tok/open-tok-session';
import { OpenTokStreamPropertyChangedEvent } from './open-tok/open-tok-stream-property-changed-event';
import { Participant } from './participant';
import { Publication } from './publication';
import { PublicationType } from './publication-type';
import { Subscription } from 'rxjs';
import { VideoRoomDataService } from '../services/video-room-data.service';
import { VideoRoomLoggerService } from '../services/video-room-logger.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { delay } from 'rxjs/operators';
import { ɵConsole } from '@angular/core';

export class LocalVideoPublication extends Publication {

    isVideoMuted: boolean;

    isVideoMuting: boolean;

    // isAudioMuted is defined on Publication level

    isAudioMuting: boolean;

    isDeviceAccessDenied: boolean;

    isAllowedToJoin: boolean;

    private _openTokPublisher: OpenTokPublisher;

    private readonly _sessionSubscriptions: Subscription[] = [];

    private _activeAudioSourceDeviceId: string;

    constructor(
        participant: Participant,
        private _openTokSession: OpenTokSession,
        private _logger: VideoRoomLoggerService,
        protected _dataService: VideoRoomDataService,
        private _roomId: string) {
        super();

        this.type = PublicationType.Video;
        this.isLocal = true;
        this.participant = participant;

        // subscribe to session events
        this._sessionSubscriptions.push(
            this._openTokSession.streamPropertyChanged$.subscribe((event) => this.onOpenTokStreamPropertyChanged(event)),
        );
    }

    destroy() {
        if (this._openTokPublisher) {
            this._openTokPublisher.destroy();
        }

        for (const subscription of this._sessionSubscriptions) {
            subscription.unsubscribe();
        }
    }

    async initializeAsync(): Promise<OT.OTError> {

        this._logger.trace('LocalVideoPublication_initializing');

        const deferred = new Deferred<OT.OTError>();

        const otPublisher = OT.initPublisher(
            this.videoElementContainer,
            {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                fitMode: 'contain',
                style: {
                    archiveStatusDisplayMode: 'off',
                    buttonDisplayMode: 'off'
                }
            },
            (error?: OT.OTError) => {
                if (error) {
                    // todo: analyze error, are all errors related to denied access?
                    if (!otPublisher.publishAudio && !otPublisher.publishVideo)
                    {
                        this.isDeviceAccessDenied = true;
                    }
                    
                    this.isDeviceAccessDenied = true;
                    this._logger.error('OT.initPublisher: error', error);
                    deferred.resolve(error);
                }
                else {
                    if (!otPublisher.publishAudio && !otPublisher.publishVideo)
                    {
                        this.isDeviceAccessDenied = true;
                    }
                    this._logger.trace('OT.initPublisher: success');
                    deferred.resolve(null);
                }
            }
        );        

        this._openTokPublisher = new OpenTokPublisher(otPublisher, this._logger);

        const error = await deferred.promise();
        if (!error) {
            this.onActiveAudioSourceChanged();
        }

        return error;
    }

    async publishAsync(): Promise<string> {
        this._logger.trace('LocalVideoPublication_publishing');
       
        const error = await this._openTokSession.publishAsync(this._openTokPublisher);
        // todo: retry couple times if it's relevant
        return error.message;
    }

    /**
     * Toggle between muted/unmuted state.
     */
    toggleAudio() {
        if (this.isAudioMuting) {
            this._logger.warning('LocalVideoPublication.toggleAudio() is in progress (ignored)');
            return;
        }

        const muteAudio = !this.isAudioMuted;
        this._logger.trace('LocalVideoPublication.toggleAudio()', { muteAudio: muteAudio });

        this._openTokPublisher.otPublisher.publishAudio(!muteAudio);
        // if the publisher is published (stream exists)
        if (this._openTokPublisher.otPublisher.stream) {
            // wait until it's changed on stream level
            // todo: set timeout
            this.isAudioMuting = true;
        }
        else {
            // if not, set immediately
            this.isAudioMuted = muteAudio;
        }
    }

    /**
     * Toggle between muted/unmuted state.
     */
    toggleVideo() {
        if (this.isVideoMuting) {
            this._logger.warning('LocalVideoPublication.toggleVideo() is in progress (ignored)');
            return;
        }

        const muteVideo = !this.isVideoMuted;
        this._logger.trace('LocalVideoPublication.toggleVideo()', { muteVideo: muteVideo });

        this._openTokPublisher.otPublisher.publishVideo(!muteVideo);
        // if the publisher is published (stream exists)
        if (this._openTokPublisher.otPublisher.stream) {
            // wait until it's changed on stream level
            // todo: add timeout - if video publish is not possible (for instance, OT_HARDWARE_ERROR when camera is occupied by another app) it's stuck in 'muting' state
            this.isVideoMuting = true;
        }
        else {
            // if not, set immediately
            this.isVideoMuted = muteVideo;
        }
    }

    private onOpenTokStreamPropertyChanged(event: OpenTokStreamPropertyChangedEvent) {
        if (event.stream !== this._openTokPublisher.otPublisher.stream) {
            // not own stream
            return;
        }

        switch (event.changedProperty) {
            case 'hasAudio':
                this.isAudioMuting = false;
                this.isAudioMuted = !event.newValue;
                break;

            case 'hasVideo':
                this.isVideoMuting = false;
                this.isVideoMuted = !event.newValue;
                break;
        }
    }

    async getAudioDevicesAsync(): Promise<Device[]> {
        // todo: remove after refactoring of setTimeout(..., 500) - see below
        // todo: fixes the workaround if access on device is requested (Firefox)
        this.onActiveAudioSourceChanged();

        const deferred = new Deferred<Device[]>();
        OT.getDevices((error: OT.OTError, devices: OT.Device[]) => {
            if (error) {
                this._logger.error('getAudioDevicesAsync()', { error: error });
                deferred.reject(error);
            }
            else {
                deferred.resolve(devices.filter(x => x.kind === 'audioInput'));
            }
        });

        return deferred.promise();
    }

    setAudioSource(audioDevice: Device) {

        // todo: test - looks like it doesn't work in Edge

        this._logger.trace('audio_source_set', { device: audioDevice });
        this._openTokPublisher.setAudioSource(audioDevice.deviceId);

        // todo: find a way, it's not clear how to subscribe on audioSource change, the workaround with setTimeout 500ms is used
        setTimeout(() => {
                this.onActiveAudioSourceChanged();
            },
            500);

        // todo: save selected audio device in local storage and use it next time from the start (if it's presented)
    }

    private onActiveAudioSourceChanged() {
        this._activeAudioSourceDeviceId = this._openTokPublisher.getAudioSourceDeviceId();
    }

    get activeAudioSourceDeviceId() {
        return this._activeAudioSourceDeviceId;
    }

    async cycleVideoAsync(): Promise<any> {
        const nextDeviceInfo = await this._openTokPublisher.cycleVideoAsync();
        // todo: set active video device id?
    }
}
