import { VideoRoomData, VideoRoomDataService } from 'video-lib';

import { GetVideoRoomInformation } from 'projects/video-lib/src/lib/models/get-video-room-information';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class DemoVideoRoomDataService extends VideoRoomDataService {
    public url = 'https://dev.setschedule.com/';
    // public url = 'localhost:4200';
    
    constructor(private _http: HttpClient, private loc: Location) {
        super();        
    }

    //async getVideoRoomDataAsync(roomId: string, outOfHours:boolean, cancellationToken?: Promise<any>)
    // async getVideoRoomDataAsync(outOfHours:boolean, cancellationToken?: Promise<any>, tokenId?: string, roomId? : string, loginId? : string): Promise<GetVideoRoomInformation> {
    async getVideoRoomDataAsync(tokenId: string, roomId : string, loginId : string, outOfHours:boolean): Promise<GetVideoRoomInformation> {
        if (window.location.href.includes('test-stg'))
        {
            this.url = 'https://dev.setschedule.com/'
        }
        else if (window.location.href.includes('localhost'))
        {
            this.url= 'https://dev.setschedule.com/';
        }
        else
        {
            this.url = 'https://api2.setschedule.com/'
        }
        // console.log("outOfHours " + outOfHours);
        //check that both strings arent empty, waf's generating url so should be safe
        if(loginId && roomId)
        {
            console.log(loginId);
            console.log(roomId);
            console.log("for concierge")
            // https://test-stg-setconnect.setschedule.com/?roomId={0}&loginId={1}
            const data = await this._http.get<GetVideoRoomInformation>(this.url + 'api2.4/Video/GetVideoRoomData?roomId=' + roomId + "&loginId=" + loginId + "&outOfHoursAttempt=" + outOfHours).toPromise();
            return data;
        }
        console.log("for users")
        const data = await this._http.get<GetVideoRoomInformation>(this.url + 'api2.4/Video/GetVideoRoomData?tokenId=' + tokenId + "&outOfHoursAttempt=" + outOfHours).toPromise();
        return data; 
        
    }

    async verifyRoomStatus(tokenId: string)    {
        if (window.location.href.includes('test-stg'))
        {
            this.url = 'https://dev.setschedule.com/'
        }
        else if (window.location.href.includes('localhost'))
        {
            this.url= 'https://dev.setschedule.com/';
        }
        else
        {
            this.url = 'https://api2.setschedule.com/'
        }

        const data = await this._http.get<boolean>(this.url + 'api2.4/Video/VerifyRoomStatus?tokenId=' + tokenId).toPromise();
        return data; 
    }

    async logOutOfHoursAttempt(tokenId: string) {
        if (window.location.href.includes('test-stg'))
        {
            this.url = 'https://dev.setschedule.com/'
        }
        else if (window.location.href.includes('localhost'))
        {
            this.url= 'https://dev.setschedule.com/';
        }
        else
        {
            this.url = 'https://api2.setschedule.com/'
        }

        var response = await this._http.post(
            this.url + 'api2.4/Video/LogOutOfHoursAttempt/' + tokenId,
             null
        );

        response.subscribe();
    }

    async disconnectVideoRoomConnections(sessionId: string) {
        if (window.location.href.includes('test-stg'))
        {
            this.url = 'https://dev.setschedule.com/'
        }
        else if (window.location.href.includes('localhost'))
        {
            this.url= 'https://dev.setschedule.com/';
        }
        else
        {
            this.url = 'https://api2.setschedule.com/'
        }
        
        var response = await this._http.post(
                    this.url + 'api2.4/Video/Disconnect/' + sessionId,
                     null
        );

        response.subscribe();
    }

    async expireConsumerToken(tokenId: string) {
        if (window.location.href.includes('test-stg'))
        {
            this.url = 'https://dev.setschedule.com/'
        }
        else if (window.location.href.includes('localhost'))
        {
            this.url= 'https://dev.setschedule.com/';
        }
        else
        {
            this.url = 'https://api2.setschedule.com/'
        }
        
        var response = await this._http.post(
                    this.url + 'api2.4/Video/Expire/' + tokenId,
                     null
        );

        response.subscribe();
    }

    async sendNewToken(tokenId: string)
    {
        if (window.location.href.includes('test-stg'))
        {
            this.url = 'https://dev.setschedule.com/'
        }
        else if (window.location.href.includes('localhost'))
        {
            this.url= 'https://dev.setschedule.com/';
        }
        else
        {
            this.url = 'https://api2.setschedule.com/'
        }
        
        var response = await this._http.post(
                    this.url + 'api2.4/Video/RequestNewLink/' + tokenId,
                     null
        );

        response.subscribe();
    }
}
