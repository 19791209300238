import { VideoRoomLoggerService } from '../../services/video-room-logger.service';
import * as OT from '@opentok/client';
import { Subject } from 'rxjs';

/*
 * Wrapper around OT.Publisher.
 */
export class OpenTokPublisher {

    get streamDestroyed$() {
        return this.streamDestroyedSubject.asObservable();
    }

    private readonly streamDestroyedSubject = new Subject<{ stream: OT.Stream; reason: string; }>();

    constructor(
        public readonly otPublisher: OT.Publisher,
        private readonly _logger: VideoRoomLoggerService) {

        this.otPublisher.on('accessAllowed', () => this.onAccessAllowed());
        this.otPublisher.on('accessDenied', () => this.onAccessDenied());
        this.otPublisher.on('accessDialogClosed', () => this.onAccessDialogClosed());
        this.otPublisher.on('accessDialogOpened', () => this.onAccessDialogOpened());

        // tbd: implement special logic that checks if audio-level has been changed for some period of time
        // todo: audioLevelUpdated should be used by call settings and microphone icon
        // tbd: the event happens very often - correct strategy is to update some bond field once per 100ms
        // this.otPublisher.on('audioLevelUpdated', (event) => {});

        this.otPublisher.on('destroyed', () => this.onDestroyed());
        this.otPublisher.on('mediaStopped', () => this.onMediaStopped());
        this.otPublisher.on('streamCreated', (event) => this.onStreamCreated(event));
        this.otPublisher.on('streamDestroyed', (event) => this.onStreamDestroyed(event));
        this.otPublisher.on('videoDimensionsChanged', (event) => this.onVideoDimensionsChanged(event));
        this.otPublisher.on('videoElementCreated', () => this.onVideoElementCreated());
    }

    destroy() {
        this.otPublisher.off('accessAllowed');
        this.otPublisher.off('accessDenied');
        this.otPublisher.off('accessDialogClosed');
        this.otPublisher.off('accessDialogOpened');

        // todo: audioLevelUpdated is on/off by CallSettings at the moment, refactor
        //this.publisher.off('audioLevelUpdated');

        this.otPublisher.off('destroyed');
        this.otPublisher.off('mediaStopped');
        this.otPublisher.off('streamCreated');
        this.otPublisher.off('streamDestroyed');
        this.otPublisher.off('videoDimensionsChanged');
        this.otPublisher.off('videoElementCreated');

        this.otPublisher.destroy();
    }

    private onAccessAllowed() {
        this._logger.trace('publisher.accessAllowed');
    }

    private onAccessDenied() {
        this._logger.trace('publisher.accessDenied');
    }

    private onAccessDialogOpened() {
        this._logger.trace('publisher.accessDialogOpened');
    }

    private onAccessDialogClosed() {
        this._logger.trace('publisher.onAccessDialogClosed');
    }

    private onDestroyed() {
        this._logger.trace('publisher.destroyed');
    }

    private onMediaStopped() {
        // tbd: log track? when it happens? how to reproduce? should happen on mute?
        this._logger.trace('publisher.mediaStopped');
    }

    private onStreamCreated(event: OT.Event<'streamCreated', OT.Publisher> & { stream: OT.Stream; }) {
        // tbd: when it should happen?
        this._logger.trace('publisher.streamCreated', { stream: event.stream });
    }

    private onStreamDestroyed(event: OT.Event<'streamDestroyed', OT.Publisher> & { stream: OT.Stream; reason: string; }) {
        this._logger.trace('publisher.streamDestroyed', { streamId: event.stream.streamId, reason: event.reason });
        this.streamDestroyedSubject.next(event);
    }

    private onVideoDimensionsChanged(event: OT.VideoDimensionsChangedEvent<OT.Publisher>) {
        this._logger.trace('publisher.videoDimensionsChanged', { newValue: event.newValue, oldValue: event.oldValue });
    }

    private onVideoElementCreated() {
        this._logger.trace('publisher.videoElementCreated');
    }

    setAudioSource(audioDeviceId: string) {
        this.otPublisher.setAudioSource(audioDeviceId);
    }

    getAudioSourceDeviceId(): string {
        return this.otPublisher.getAudioSource().getSettings().deviceId;
    }

    async cycleVideoAsync(): Promise<{ deviceId: string }> {
        return this.otPublisher.cycleVideo();
    }
}
