import * as OT from '@opentok/client';

import { Deferred } from '../helpers/deferred';
import { OpenTokSession } from './open-tok/open-tok-session';
import { OpenTokStreamPropertyChangedEvent } from './open-tok/open-tok-stream-property-changed-event';
import { OpenTokSubscriber } from './open-tok/open-tok-subscriber';
import { Publication } from './publication';
import { PublicationType } from './publication-type';
import { RemoteParticipant } from './remote-participant';
import { Subscription } from 'rxjs';
import { VideoRoomLoggerService } from '../services/video-room-logger.service';

export class RemotePublication extends Publication {

    participantId: string;

    publicationId: string;

    private fullName: string;

    private _openTokSubscriber: OpenTokSubscriber;

    private readonly _sessionSubscriptions: Subscription[] = [];

    constructor(
        private _otStream: OT.Stream,
        private _openTokSession: OpenTokSession,
        private _logger: VideoRoomLoggerService) {
        super();

        this.type = this._otStream.videoType === 'screen'
            ? PublicationType.Screen
            : PublicationType.Video;
        this.isLocal = false;

        this.participantId = RemoteParticipant.getParticipantId(this._otStream.connection);
        this.publicationId = RemotePublication.getPublicationId(this._otStream);

        // subscribe to session events
        this._sessionSubscriptions.push(
            this._openTokSession.streamPropertyChanged$.subscribe((event) => this.onOpenTokStreamPropertyChanged(event)),
        );
    }

    destroy() {
        if (this._openTokSubscriber) {
            this._openTokSubscriber.destroy();
        }

        for (const subscription of this._sessionSubscriptions) {
            subscription.unsubscribe();
        }
    }

    /**
     * Start receiving remote publication.
     * @returns OT.Error or null if success.
     */
    async subscribeAsync(): Promise<OT.OTError> {

        this._logger.trace('RemotePublication_subscribing', { streamId: this._otStream.streamId });

        const deferred = new Deferred();

        // set initial values
        this.isAudioMuted = !this._otStream.hasAudio;

        const otSubscriber = this._openTokSession.otSession.subscribe(
            this._otStream,
            this.videoElementContainer,
            {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                fitMode: 'contain',
                style: {
                    buttonDisplayMode: 'off'
                },
                subscribeToVideo:true,
                insertDefaultUI: true
            },
            (error?: OT.OTError) => {
                if (error) {
                    this._logger.error('session.subscribe: error', error);
                    deferred.resolve(error);

                    // todo: repeat couple of times if failed
                    // tbd: reconnect if destroyed due to internet issues? can this happen without session disconnect?
                }
                else {
                    this._logger.trace('session.subscribe: success');
                    deferred.resolve(null);

                    // prepare subscriber - for instance, set backgroundImageURI for participant without video stream
                }
            }
        );

        this._openTokSubscriber = new OpenTokSubscriber(otSubscriber, this._logger);

        return deferred.promise();
    }

    private onOpenTokStreamPropertyChanged(event: OpenTokStreamPropertyChangedEvent) {
        if (event.stream !== this._otStream) {
            // not own stream
            return;
        }

        switch (event.changedProperty) {
            case 'hasAudio':
                this.isAudioMuted = !event.newValue;
                break;
        }
    }

    static getPublicationId(otStream: OT.Stream): string {
        return otStream.streamId;
    }
}
