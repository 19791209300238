import { Component, OnInit, OnDestroy } from '@angular/core';
import { VideoRoomDisplayMode, DataChannel } from 'video-lib';
import { DemoVideoRoomLoggerService } from '../services/video-logger.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'connect-component',
    templateUrl: './connect.component.html',
    styleUrls: ['./connect.component.scss']
})
export class VideoRoomTestPageComponent implements OnInit, OnDestroy {

    VideoRoomDisplayMode = VideoRoomDisplayMode;

    dataChannel: DataChannel;

    private readonly _dataChannelSubscriptions: Subscription[] = [];

    constructor(private _logger: DemoVideoRoomLoggerService) {
        this._logger.enableExtendedLogging({
            consoleLog: true
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        for (const subscription of this._dataChannelSubscriptions) {
            subscription.unsubscribe();
        }

        this._logger.enableExtendedLogging({
            consoleLog: false
        });
    }

    onDataChannelOpened(dataChannel: DataChannel) {
        this.dataChannel = dataChannel;
        this._dataChannelSubscriptions.push(
            this.dataChannel.messageReceived$.subscribe((message) => {
            }),
        );
    }

    async onSendMessage() {
        if (!this.dataChannel) {
            console.error('DataChannel is not presented.');
            return;
        }

        this.dataChannel.sendMessageAsync({
            type: 'msg',
            data: JSON.stringify({ text: 'Test Text', userId: 1 })
        });
    }
}
