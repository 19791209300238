import { VideoRoomLoggerService } from '../../services/video-room-logger.service';
import * as OT from '@opentok/client';

/**
 * @summary Wrapper around OT.Subscriber.
 */
export class OpenTokSubscriber {

    _streamId: string;

    constructor(
        public readonly otSubscriber: OT.Subscriber,
        private readonly _logger: VideoRoomLoggerService) {

        this._streamId = otSubscriber.stream.streamId;

        this.otSubscriber.on('audioLevelUpdated', (event) => this.onAudioLevelUpdated(event));
        this.otSubscriber.on('connected', () => this.onConnected());
        this.otSubscriber.on('destroyed', (event) => this.onDestroyed(event));
        this.otSubscriber.on('videoDimensionsChanged', (event) => this.onVideoDimensionsChanged(event));
        this.otSubscriber.on('videoDisabled', (event) => this.onVideoDisabled(event));
        this.otSubscriber.on('videoDisableWarning', () => this.onVideoDisableWarning());
        this.otSubscriber.on('videoDisableWarningLifted', () => this.onVideoDisableWarningLifted());
        this.otSubscriber.on('videoElementCreated', () => this.onVideoElementCreated());
        this.otSubscriber.on('videoEnabled', (event) => this.onVideoEnabled(event));
    }

    destroy() {
        this.otSubscriber.off('audioLevelUpdated');
        this.otSubscriber.off('connected');
        this.otSubscriber.off('destroyed');
        this.otSubscriber.off('videoDimensionsChanged');
        this.otSubscriber.off('videoDisabled');
        this.otSubscriber.off('videoDisableWarning');
        this.otSubscriber.off('videoDisableWarningLifted');
        this.otSubscriber.off('videoElementCreated');
        this.otSubscriber.off('videoEnabled');
    }

    private onAudioLevelUpdated(event: OT.Event<'audioLevelUpdated', OT.Subscriber> & { audioLevel: number }) {
        // tbd: do we need this handler?
    }

    private onConnected() {
        this._logger.trace('subscriber.connected', { streamId: this._streamId });
    }

    private onDestroyed(event: OT.Event<'destroyed', OT.Subscriber> & { reason: string }) {
        this._logger.trace('subscriber.destroyed', { streamId: this._streamId, reason: event.reason });
    }

    private onVideoDimensionsChanged(event: OT.VideoDimensionsChangedEvent<OT.Subscriber>) {
        this._logger.trace('subscriber.videoDimensionsChanged', { streamId: this._streamId, newValue: event.newValue, oldValue: event.oldValue });
    }

    private onVideoDisabled(event: OT.Event<'videoDisabled', OT.Subscriber> & { reason: string }) {
        this._logger.trace('subscriber.videoDisabled', { streamId: this._streamId, reason: event.reason });
    }

    private onVideoDisableWarning() {
        this._logger.trace('subscriber.videoDisableWarning', { streamId: this._streamId });
    }

    private onVideoDisableWarningLifted() {
        this._logger.trace('subscriber.videoDisableWarningLifted', { streamId: this._streamId });
    }

    private onVideoElementCreated() {
        this._logger.trace('subscriber.videoElementCreated', { streamId: this._streamId });
    }

    private onVideoEnabled(event: OT.Event<'videoEnabled', OT.Subscriber> & { reason: string }) {
        this._logger.trace('subscriber.videoEnabled', { streamId: this._streamId, reason: event.reason });
    }
}
